import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import style from "scss/pages/chiSiamo.module.scss";
import Lines from "components/lines";
import Image from "components/image";
import ArrowDown from 'components/arrowDown/index'

class ChiSiamo extends React.Component {
  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Chi Siamo" description={'Realizziamo e-commerce, siti internet, progetti di automation a Venezia. Affidati alla nostra web agency per la tua strategia SEO; SEM e per vendere online!'}/>

        <div className={style.header}>
          <Lines />
          <div className={'container'}>
            <div className={'row'}>

              <div className={'col-md-5'}>
                <h2 className={'has-color-elements-dark'}>Creiamo ambienti digitali su misura, <span className={'has-color-primary-negative'}>digital outfit</span>, siti web, <span className={'has-color-primary-negative'}>applicazioni</span> data driven e human oriented.</h2>
              </div>

              <div className={'offset-md-1 col-md-5 image'}>
                <p className={`has-color-elements-dark`}>Siamo il partner che ti accompagna nella progettazione e sviluppo di <span className={'has-color-primary-negative'}>prodotti digitali</span> che creino valore per il tuo business. Progettiamo <span className={'has-color-primary-negative'}>soluzioni tecnologiche</span> e creative, elaborando le idee imprenditoriali dei nostri clienti. Il nostro obiettivo è creare connessioni tra aziende e persone attraverso esperienze digitali uniche, sia per progetti di <span className={'has-color-primary-negative'}>business online</span> tradizionali come ecommerce e b2b, sia per <span className={'has-color-primary-negative'}>progetti di business più innovativi</span> che hanno nell'online un canale privilegiato. Grazie alle competenze trasversali, allo spirito di squadra e alle esperienze maturate, Dinamiza copre tutte le componenti progettuali con un alto livello di seniority.</p>
                <p className={`has-color-elements-dark ${style.header__textBottom}`}><span className={'has-color-dinamiza has-font-element'}>DINAMIZA</span> è parte del gruppo <span className={'has-color-primary-negative'}>STARSOFTWARE</span></p>
              </div>

            </div>

            <div className={style.header__payoff}>
              <hr />
              <h4><span>La tua visione</span>le nostre creazioni</h4>
            </div>

          </div>
          <ArrowDown />
        </div>

        <div className={`${style.section1} is-overlap`}>

          <div className={`container-fluid no-padding`}>
            <div className={`row`}>
              <div className={`offset-md-7 col-md-5`}>

                <Image url={'/img/pages/chisiamo-1.jpg'} className={style.section1__image} />

              </div>
            </div>
          </div>
          <div className={`container`}>
            <div className={`row`}>
              <div className={`col-md-6`}>
                <div className={style.section1__content}>
                  <p><span className={'has-color-dinamiza has-font-element'}>METODO</span> </p>
                  <p>Il nostro metodo è circolare: si finisce con un nuovo inizio, con una rinnovata consapevolezza. La nostra attenzione si concentra nel raggiungimento di quattro obiettivi:</p>
                  <p>Ascolto: analisi del contesto e definizione degli obiettivi.</p>
                  <p>Dialogo: definizione della strategia.</p>
                  <p>Sviluppo: rilasci e verifiche periodiche con il cliente.</p>
                  <p>Verifica: misuriamo i risultati, valutiamo se variare la strategia.</p>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className={`${style.section2} is-overlap`}>

          <div className={`container-fluid no-padding`}>
            <div className={`row`}>
              <div className={`col-md-5`}>

                <Image url={'/img/pages/chisiamo-2.jpg'} className={style.section2__image} />

              </div>
            </div>
          </div>
          <div className={`container`}>
            <div className={`row`}>
              <div className={`offset-md-6 col-md-6`}>
                <div className={style.section2__content}>
                  <p><span className={'has-color-dinamiza has-font-element'}>I NOSTRI VALORI</span></p>
                  <p>Lealtà: ci piace essere schietti. Se una cosa non va fatta, te la sconsigliamo. Se la strategia va corretta, ci rimettiamo in gioco. Siamo un tuo partner, lavoriamo per crescere assieme. </p>
                  <p>Impegno: diamo il massimo perché preferiamo terminare la giornata stanchi ma consapevoli di aver dato tutto. </p>
                  <p>Passione: quel qualcosa in più del fare il compitino, un'energia che ci guida diventando contagiosa.</p>
                  <p>Organizzazione: quando qualcosa va storto, abbiamo sempre un piano B.</p>
                  <p>Competenza: ciò che ci compete lo facciamo molto bene, per il resto siamo molto veloci a imparare e disponibili a collaborare.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${style.section1} is-overlap`}>

          <div className={`container-fluid no-padding`}>
            <div className={`row`}>
              <div className={`offset-md-7 col-md-5`}>

                <Image url={'/img/pages/chisiamo-3.jpg'} className={style.section1__image} />

              </div>
            </div>
          </div>
          <div className={`container`}>
            <div className={`row`}>
              <div className={`col-md-6`}>
                <div className={style.section1__content}>
                  <span></span>
                  <p>DINAMIZA è parte di STARSOFTWARE Group, azienda storica del Veneziano con una forte competenza tecnologica che copre l’ambito di infrastruttura informatica e di soluzioni per l'automation.</p>
                  <p>La missione di STARSOFTWARE Group è dotare il cliente di strumenti informatici che gli consentano di cogliere le opportunità attuali e di prepararsi ad affrontare le sfide del futuro.</p>
                  <p>I diversi ambiti di business sono sviluppati da divisioni specialistiche dedicate che lavorano in modo integrato, unite dalla solidità e dalla visione strategica del gruppo.</p>
                  <p>I vantaggi di questa integrazione sono la massima flessibilità e velocità nel corrispondere e soddisfare le esigenze del cliente.</p>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className={style.section3}>
          <div className={`container`}>
            <div className={`row`}>
              <div className={`col-md-5`}>
                <div className={style.section3__content}>
                  <img className={style.section3__logo} src='/img/logo_seon.svg' />
                  <p><span className={style.section3__red}>SEON</span> è la divisione del Gruppo Starsoftware che presidia l’ambito ICT del cliente a 360 gradi attraverso la creazione di infrastrutture tecnologiche realizzate sulle reali esigenze delle PMI.</p>
                  <a href={'https://seon.it/'} data-hover="true" target={'_blank'}><img src='/img/icon_link.svg' />https://seon.it/</a>
                </div>
              </div>
              <div className={`offset-md-2 col-md-5 no-padding`}>
                <div className={style.section3__content}>
                  <img className={style.section3__logo} src='/img/logo_cylix.svg' />
                  <p><span className={style.section3__black}>CYLIX</span> è la divisione del Gruppo Starsoftware dedicata alla creazione di soluzioni informatiche che mirano alla razionalizzazione e ottimizzazione dei processi interni alle PMI.</p>
                  <a href={'https://cylix.it/'} data-hover="true" target={'_blank'}><img src='/img/icon_link.svg' />https://cylix.it/</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Layout>
    )
  }
}

export default ChiSiamo
